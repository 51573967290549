import React from "react";
import "./Style/Logo.css";

const Logo: React.FC = () => {
  return (
    <div className="logo-container">
      <img src="/logoescritorio.png" alt="Logo do Escritório" className="logo" />
    </div>
  );
};

export default Logo;
