import React from "react";
import { useNavigate } from "react-router-dom";
import "./Style/BackIcon.css";

const BackIcon: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/questionario");
  };

  return (
    <div className="back-icon" onClick={handleClick}>
      <img src="/voltar.png" alt="Voltar" className="icon" />
    </div>
  );
};

export default BackIcon;
