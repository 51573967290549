import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // CSS global
import App from './App';
import reportWebVitals from './reportWebVitals';

// Função para adicionar fontes dinamicamente
const addFont = () => {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

// Adiciona a fonte ao carregar o projeto
addFont();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Monitoramento de desempenho opcional
reportWebVitals();
