import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Style/SettingsIcon.css";
import api from "../Services/api";

const SettingsIcon: React.FC = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await api.get("/users/auth/me/");
        setUserRole(response.data.cargo); // Obtém o cargo do usuário logado
      } catch (error) {
        console.error("Erro ao buscar os dados do usuário:", error);
        setUserRole(null); // Reseta o estado em caso de erro
      }
    };

    fetchUserRole();
  }, []);

  const handleClick = () => {
    if (userRole === "admin") {
      navigate("/manage-users");
    } else {
      alert("Acesso negado: Apenas administradores podem acessar esta página.");
    }
  };

  return (
    <div className="settings-icon" onClick={handleClick}>
      <img src="/engrenagem.png" alt="Configurações" className="icon" />
    </div>
  );
};

export default SettingsIcon;
