import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./Screens/Login";
import Questionario from "./Screens/Questionario";
import ManageUsers from "./Screens/ManageUsers";
import PublicRoute from "./Components/PublicRoute";
import ProtectedRoute from "./Components/ProtectedRoute";
import RoleProtectedRoute from "./Components/RoleProtectedRoute";
import { AuthProvider } from "./Contexts/AuthContext";
import ThankYouPage from "./Screens/ThankYouPage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Rota Pública para Login */}
          <Route
            path="/"
            element={
                <Login />
            }
          />

          {/* Rota Privada para Questionário */}
          <Route
            path="/questionario"
            element={
              <ProtectedRoute>
                <Questionario />
              </ProtectedRoute>
            }
          />

          {/* Rota Protegida por Cargo para Gerenciamento de Usuários */}
          <Route element={<RoleProtectedRoute allowedRoles={["admin"]} redirectPath="/questionario" />}>
            <Route path="/manage-users" element={<ManageUsers />} />
          </Route>

          {/* Rota Privada para Página Final */}
          <Route
            path="/final"
            element={
              <ProtectedRoute>
                <ThankYouPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
