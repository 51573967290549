import axios from "axios";

const api = axios.create({
  baseURL: "https://garciaemacedo.com/api",
  withCredentials: true,
});

// Adiciona o token em todas as requisições protegidas
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // Recupera o token do localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const fetchLoggedInUser = async () => {
  try {
    const response = await api.get("/users/auth/me/");
    return response.data; // Retorna os dados do usuário
  } catch (error) {
    console.error("Erro ao buscar o usuário logado:", error);
    throw new Error("Não foi possível carregar os dados do usuário logado.");
  }
};

export default api;