// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
  }
  
  .modal-content {
	background: white;
	border-radius: 8px;
	padding: 20px;
	width: 400px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
	margin-bottom: 20px;
	font-size: 1.5rem;
	color: #333;
	text-align: center;
  }
  
  .modal-content label {
	display: block;
	margin-bottom: 10px;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	color: #333;
  }
  
  .modal-content input,
  .modal-content select {
	width: 100%;
	padding: 8px;
	margin-top: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-family: "Poppins", sans-serif;
  }
  
  .modal-actions {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
  }
  
  .modal-actions button {
	background-color: #b7976d;
	border: none;
	color: white;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
  }
  
  .modal-actions button:hover {
	background-color: rgb(212, 138, 0);
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Style/EditUserModal.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,MAAM;CACN,OAAO;CACP,QAAQ;CACR,SAAS;CACT,8BAA8B;CAC9B,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;EACZ;;EAEA;CACD,iBAAiB;CACjB,kBAAkB;CAClB,aAAa;CACb,YAAY;CACZ,wCAAwC;EACvC;;EAEA;CACD,mBAAmB;CACnB,iBAAiB;CACjB,WAAW;CACX,kBAAkB;EACjB;;EAEA;CACD,cAAc;CACd,mBAAmB;CACnB,kCAAkC;CAClC,eAAe;CACf,WAAW;EACV;;EAEA;;CAED,WAAW;CACX,YAAY;CACZ,eAAe;CACf,sBAAsB;CACtB,kBAAkB;CAClB,kCAAkC;EACjC;;EAEA;CACD,aAAa;CACb,8BAA8B;CAC9B,gBAAgB;EACf;;EAEA;CACD,yBAAyB;CACzB,YAAY;CACZ,YAAY;CACZ,iBAAiB;CACjB,kBAAkB;CAClB,eAAe;CACf,kCAAkC;CAClC,eAAe;EACd;;EAEA;CACD,kCAAkC;EACjC","sourcesContent":[".modal-overlay {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tbackground: rgba(0, 0, 0, 0.5);\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tz-index: 1000;\n  }\n  \n  .modal-content {\n\tbackground: white;\n\tborder-radius: 8px;\n\tpadding: 20px;\n\twidth: 400px;\n\tbox-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .modal-content h2 {\n\tmargin-bottom: 20px;\n\tfont-size: 1.5rem;\n\tcolor: #333;\n\ttext-align: center;\n  }\n  \n  .modal-content label {\n\tdisplay: block;\n\tmargin-bottom: 10px;\n\tfont-family: \"Poppins\", sans-serif;\n\tfont-size: 1rem;\n\tcolor: #333;\n  }\n  \n  .modal-content input,\n  .modal-content select {\n\twidth: 100%;\n\tpadding: 8px;\n\tmargin-top: 5px;\n\tborder: 1px solid #ccc;\n\tborder-radius: 4px;\n\tfont-family: \"Poppins\", sans-serif;\n  }\n  \n  .modal-actions {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-top: 20px;\n  }\n  \n  .modal-actions button {\n\tbackground-color: #b7976d;\n\tborder: none;\n\tcolor: white;\n\tpadding: 8px 16px;\n\tborder-radius: 4px;\n\tcursor: pointer;\n\tfont-family: \"Poppins\", sans-serif;\n\tfont-size: 1rem;\n  }\n  \n  .modal-actions button:hover {\n\tbackground-color: rgb(212, 138, 0);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
