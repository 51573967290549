import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import "./Style/LogoutButton.css";
import api from "../Services/api";

const LogoutButton: React.FC = () => {
	const { logout } = useAuth();
	const navigate = useNavigate();
  
	const handleLogout = async () => {
	  try {
		// Faz a requisição para a rota de logout
		await api.post("/users/logout/");
		logout(); // Remove o token do contexto e do localStorage
		navigate("/"); // Redireciona para a página de login após logout
	  } catch (error) {
		console.error("Erro ao fazer logout:", error);
		alert("Erro ao fazer logout. Tente novamente.");
	  }
	};
  
	return (
	  <button className="logout-button" onClick={handleLogout}>
		Sair
	  </button>
	);
  };
  
  export default LogoutButton;