// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contêiner principal */
.thank-you-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #585163; /* Cor de fundo */
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

/* Logo */
.thank-you-page .logo {
    max-width: 300px; /* Ajuste conforme necessário para tornar a imagem grande */
    border: 5px solid #585163; /* Borda uniforme ao redor da imagem */
    margin-bottom: 30px; /* Espaço abaixo da imagem */
}

/* Texto de agradecimento */
.thank-you-page .thank-you-text {
    font-size: 2.5rem;
    color: #b7976d;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

/* Botão de logout */
.thank-you-page .logout-btn {
    background-color: #b7976d;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.thank-you-page .logout-btn:hover {
    background-color: rgb(212, 138, 0);
}
`, "",{"version":3,"sources":["webpack://./src/Screens/Style/ThankYouPage.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB,EAAE,iBAAiB;IAC5C,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA,SAAS;AACT;IACI,gBAAgB,EAAE,2DAA2D;IAC7E,yBAAyB,EAAE,sCAAsC;IACjE,mBAAmB,EAAE,4BAA4B;AACrD;;AAEA,2BAA2B;AAC3B;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA,oBAAoB;AACpB;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":["/* Contêiner principal */\n.thank-you-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    background-color: #585163; /* Cor de fundo */\n    text-align: center;\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n/* Logo */\n.thank-you-page .logo {\n    max-width: 300px; /* Ajuste conforme necessário para tornar a imagem grande */\n    border: 5px solid #585163; /* Borda uniforme ao redor da imagem */\n    margin-bottom: 30px; /* Espaço abaixo da imagem */\n}\n\n/* Texto de agradecimento */\n.thank-you-page .thank-you-text {\n    font-size: 2.5rem;\n    color: #b7976d;\n    margin-bottom: 20px;\n    font-family: \"Poppins\", sans-serif;\n    text-align: center;\n}\n\n/* Botão de logout */\n.thank-you-page .logout-btn {\n    background-color: #b7976d;\n    border: none;\n    color: white;\n    padding: 10px 20px;\n    border-radius: 8px;\n    font-family: \"Poppins\", sans-serif;\n    font-weight: 600;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.thank-you-page .logout-btn:hover {\n    background-color: rgb(212, 138, 0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
