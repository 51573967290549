import "./Style/Login.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../Services/api";
import Logo from "../Components/Logo";

interface ErrorType {
  message: string;
}

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<ErrorType | null>(null);

  // Função para validar o formato do email
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Recupera o email do localStorage ao carregar a página
  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  // Função para realizar login
  async function loginClick() {
    if (!email || !password) {
      setError({ message: "Por favor, preencha todos os campos." });
      return;
    }

    if (!isValidEmail(email)) {
      setError({ message: "Por favor, insira um email válido." });
      return;
    }

    try {
      const response = await api.post("/users/login", { email, password });
      if (response.status === 200) {
        const token = response.data.token; // Supomos que o token vem no response
        localStorage.setItem("authToken", token); // Salva o token no localStorage

        // Salva o email no localStorage para reutilização futura
        localStorage.setItem("savedEmail", email);

        navigate("/questionario"); // Redireciona para o questionário
        window.location.reload(); // Força a recarga da página
      }
    } catch (error: any) {
      if (error.response) {
        const apiError = error.response.data?.error;
        setError({ message: apiError || "Erro inesperado no servidor." });
      } else if (error.request) {
        setError({ message: "Erro de rede: sem resposta do servidor." });
      } else {
        setError({ message: "Erro ao configurar a requisição." });
      }
    }
  }

  // Detecta a tecla Enter e realiza login
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      loginClick();
    }
  };

  function navigateToCadastro() {
    navigate("/cadastro");
  }

  return (
    <div className="login" onKeyDown={handleKeyDown}>
      <Logo />
      <div className="login-container">
        <h1>Login</h1>
        <input
          type="text"
          placeholder="Email"
          className="input"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(null); // Limpa o erro ao digitar
          }}
        />
        <input
          type="password"
          placeholder="Senha"
          className="input"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(null); // Limpa o erro ao digitar
          }}
        />
        {error && <p className="error">{error.message}</p>}
        <button className="login-btn" onClick={loginClick}>
          Entrar
        </button>
      </div>
    </div>
  );
}

export default Login;
