import React, { useState } from "react";
import "./Style/EditUserModal.css";

interface EditUserModalProps {
  user?: {
    id?: number;
    name: string;
    email: string;
    cargo?: string;
    status?: string;
    password?: string;
  };
  isNewUser?: boolean; // Define se o modal está sendo usado para criar um usuário
  onClose: () => void;
  onSave: (updatedUser: any) => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  user,
  isNewUser = false,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState(""); // Sempre pede uma senha para criação
  const [cargo, setCargo] = useState(user?.cargo || "user");
  const [status, setStatus] = useState(user?.status || "pendente");
  const [error, setError] = useState<string | null>(null);

  const validatePassword = (password: string): boolean => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSave = () => {
    // Valida os campos antes de salvar
    if (isNewUser && !password) {
      setError("A senha é obrigatória para criação de um novo usuário.");
      return;
    }

    if (password && !validatePassword(password)) {
      setError(
        "A senha deve seguir os requisitos mínimos determinados."
      );
      return;
    }

    setError(null); // Limpa erros anteriores

    const updatedUser = isNewUser
      ? { name, email, password } // Para criação, a senha sempre será obrigatória
      : {
          id: user?.id,
          name,
          email,
          cargo,
          status,
          ...(password && { password }), // Adiciona a senha apenas se for preenchida
        };

    onSave(updatedUser);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{isNewUser ? "Criar Usuário" : "Editar Usuário"}</h2>
        <label>
          Nome:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Senha {isNewUser && "(Obrigatória)"}:
          <input
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError(null); // Limpa o erro ao digitar
            }}
            required={isNewUser}
          />
          <small>
            A senha deve ter pelo menos 8 caracteres, incluindo uma letra
            maiúscula, uma letra minúscula, um número e um caractere especial.
          </small>
        </label>
        {!isNewUser && (
          <>
            <label>
              Cargo:
              <select
                value={cargo}
                onChange={(e) => setCargo(e.target.value)}
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </label>
            <label>
              Status:
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="pendente">Pendente</option>
                <option value="ativo">Ativo</option>
              </select>
            </label>
          </>
        )}
        {error && <p className="error">{error}</p>}
        <div className="modal-actions">
          <button onClick={handleSave}>Salvar</button>
          <button onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
