import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Services/api";
import "./Style/Questionario.css";
import LogoutButton from "../Components/LogoutButton";
import Logo from "../Components/Logo";
import SettingsIcon from "../Components/SettingsIcon";

interface SubQuestion {
  id: string;
  text: string;
}

interface Question {
  id: string;
  text: string;
  subquestions: SubQuestion[];
}

const Questionnaire: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [responses, setResponses] = useState<{ [id: string]: string }>(
    () => JSON.parse(localStorage.getItem("responses") || "{}")
  );
  const [additionalResponses, setAdditionalResponses] = useState<{ [id: string]: string }>(
    () => JSON.parse(localStorage.getItem("additionalResponses") || "{}")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await api.get("/questionario");
        setQuestions(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Erro ao carregar o questionário:", err);
        setError("Não foi possível carregar o questionário. Tente novamente mais tarde.");
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  // Salvar respostas no localStorage
  useEffect(() => {
    localStorage.setItem("responses", JSON.stringify(responses));
    localStorage.setItem("additionalResponses", JSON.stringify(additionalResponses));
  }, [responses, additionalResponses]);

  const handleAnswer = (id: string, answer: string) => {
    setResponses({ ...responses, [id]: answer });
  };

  const handleAdditionalAnswer = (id: string, answer: string) => {
    setAdditionalResponses({ ...additionalResponses, [id]: answer });
  };

  const nextQuestion = () => {
    const currentQuestion = questions[currentIndex];

    if (currentQuestion.text.endsWith("?") && !responses[currentQuestion.id]) {
      alert("Por favor, responda à pergunta principal antes de avançar.");
      return;
    }

    if (
      currentQuestion.text.endsWith("?") &&
      responses[currentQuestion.id] === "sim" &&
      currentQuestion.subquestions.some((sub) => !responses[sub.id])
    ) {
      alert("Por favor, responda a todas as subquestões visíveis antes de avançar.");
      return;
    }

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const finishQuestionnaire = async () => {
    try {
      setIsLoading(true);
  
      const formattedResponses = questions.map((question) => ({
        questionId: question.id,
        questionText: question.text,
        resposta: responses[question.id] || "",
        respostaAdicional: additionalResponses[question.id] || "",
        subQuestions: question.subquestions.map((sub) => ({
          id: sub.id,
          text: sub.text,
          resposta: responses[sub.id] || "",
          respostaAdicional: additionalResponses[sub.id] || "", // Inclui observação adicional
        })),
      }));
  
      await api.post("/response", { respostas: formattedResponses });
  
      setIsSuccess(true);
    } catch (error) {
      console.error("Erro ao finalizar o questionário:", error);
      alert("Ocorreu um erro ao gerar o relatório. Tente novamente mais tarde.");
    } finally {
      setIsLoading(false);
    }
  };
  

  const prevQuestion = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (loading) return <p>Carregando questionário...</p>;

  if (error) return <p>{error}</p>;

  if (questions.length === 0) return <p>Nenhuma pergunta disponível.</p>;

  const currentQuestion: Question = questions[currentIndex];

  return (
    <div className="questionnaire">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Gerando relatório, por favor aguarde...</p>
        </div>
      )}

      {isSuccess && (
        <div className="success-popup">
          <p>Relatório gerado com sucesso!</p>
          <button onClick={() => navigate("/final")}>Fechar</button>
        </div>
      )}

      <div>
        <Logo />
        <SettingsIcon />
      </div>

      <div>
        <LogoutButton />
      </div>

      <div className="questionnaire-container">
        <h1>Questionário</h1>
        <div>
          <h3>{currentQuestion.text}</h3>
          {currentQuestion.text.endsWith("?") && (
            <>
              <select
                className="select"
                value={responses[currentQuestion.id] || ""}
                onChange={(e) => handleAnswer(currentQuestion.id, e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="sim">Sim</option>
                <option value="parcial">Parcial</option>
                <option value="nao">Não</option>
              </select>

              {/* Caixa de observação adicional */}
              <textarea
                className="textarea"
                placeholder="Observações adicionais (opcional)"
                value={additionalResponses[currentQuestion.id] || ""}
                onChange={(e) => handleAdditionalAnswer(currentQuestion.id, e.target.value)}
              ></textarea>
            </>
          )}
        </div>

        {/* Renderizar subperguntas */}
        <div className="subquestion-group">
          {currentQuestion.text.endsWith("?")
            ? ["sim", "parcial"].includes(responses[currentQuestion.id]) &&
              currentQuestion.subquestions.map((sub) => (
                <div key={sub.id}>
                  <label>{sub.text}</label>
                  {sub.text.endsWith("?") && (
                    <>
                      <select
                        className="select"
                        value={responses[sub.id] || ""}
                        onChange={(e) => handleAnswer(sub.id, e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="sim">Sim</option>
                        <option value="parcial">Parcial</option>
                        <option value="nao">Não</option>
                      </select>

                      {/* Caixa de observação adicional */}
                      <textarea
                        className="textarea"
                        placeholder="Observações adicionais (opcional)"
                        value={additionalResponses[sub.id] || ""}
                        onChange={(e) => handleAdditionalAnswer(sub.id, e.target.value)}
                      ></textarea>
                    </>
                  )}
                </div>
              ))
            : currentQuestion.subquestions.map((sub) => (
                <div key={sub.id}>
                  <label>{sub.text}</label>
                  {sub.text.endsWith("?") && (
                    <>
                      <select
                        className="select"
                        value={responses[sub.id] || ""}
                        onChange={(e) => handleAnswer(sub.id, e.target.value)}
                      >
                        <option value="">Selecione</option>
                        <option value="sim">Sim</option>
                        <option value="parcial">Parcial</option>
                        <option value="nao">Não</option>
                      </select>

                      {/* Caixa de observação adicional */}
                      <textarea
                        className="textarea"
                        placeholder="Observações adicionais (opcional)"
                        value={additionalResponses[sub.id] || ""}
                        onChange={(e) => handleAdditionalAnswer(sub.id, e.target.value)}
                      ></textarea>
                    </>
                  )}
                </div>
              ))}
        </div>

        <div>
          <button
            className="navigation-btn"
            onClick={prevQuestion}
            disabled={currentIndex === 0}
          >
            Anterior
          </button>
          {currentIndex === questions.length - 1 ? (
            <button className="navigation-btn" onClick={finishQuestionnaire}>
              Finalizar
            </button>
          ) : (
            <button
              className="navigation-btn"
              onClick={nextQuestion}
              disabled={currentIndex === questions.length - 1}
            >
              Próxima
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
