// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-icon {
  position: fixed;
  bottom: 20px; /* Alinhado ao canto inferior direito */
  right: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000;
}

.settings-icon .icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.settings-icon:hover .icon {
  transform: scale(1.1); /* Efeito de zoom ao passar o mouse */
  opacity: 0.8; /* Reduz a opacidade */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Style/SettingsIcon.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY,EAAE,uCAAuC;EACrD,WAAW;EACX,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kDAAkD;AACpD;;AAEA;EACE,qBAAqB,EAAE,qCAAqC;EAC5D,YAAY,EAAE,sBAAsB;AACtC","sourcesContent":[".settings-icon {\n  position: fixed;\n  bottom: 20px; /* Alinhado ao canto inferior direito */\n  right: 20px;\n  width: 50px;\n  height: 50px;\n  cursor: pointer;\n  z-index: 1000;\n}\n\n.settings-icon .icon {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  transition: transform 0.3s ease, opacity 0.3s ease;\n}\n\n.settings-icon:hover .icon {\n  transform: scale(1.1); /* Efeito de zoom ao passar o mouse */\n  opacity: 0.8; /* Reduz a opacidade */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
