import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import api from "../Services/api";

interface RoleProtectedRouteProps {
  allowedRoles: string[]; // Lista de cargos permitidos
  redirectPath?: string; // Caminho de redirecionamento se não autorizado
}

const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({
  allowedRoles,
  redirectPath = "/", // Redireciona para a home por padrão
}) => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await api.get("/users/auth/me/");
        setUserRole(response.data.cargo); // Obtém o cargo do usuário
      } catch (error) {
        console.error("Erro ao buscar os dados do usuário:", error);
        setUserRole(null); // Reseta o estado em caso de erro
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  if (loading) return <p>Carregando...</p>; // Exibe carregamento enquanto busca o cargo

  return allowedRoles.includes(userRole || "") ? <Outlet /> : <Navigate to={redirectPath} replace />;
};

export default RoleProtectedRoute;
