// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contêiner da logo */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Espaço entre a logo e o questionário */
}

/* Estilo da imagem da logo */
.logo {
    max-width: 100%; /* Responsivo: nunca excede o tamanho do contêiner */
    max-height: 100px; /* Limita a altura máxima */
    object-fit: contain; /* Garante que a imagem seja exibida proporcionalmente */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Style/Logo.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB,EAAE,yCAAyC;AAClE;;AAEA,6BAA6B;AAC7B;IACI,eAAe,EAAE,oDAAoD;IACrE,iBAAiB,EAAE,2BAA2B;IAC9C,mBAAmB,EAAE,wDAAwD;AACjF","sourcesContent":["/* Contêiner da logo */\n.logo-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 20px; /* Espaço entre a logo e o questionário */\n}\n\n/* Estilo da imagem da logo */\n.logo {\n    max-width: 100%; /* Responsivo: nunca excede o tamanho do contêiner */\n    max-height: 100px; /* Limita a altura máxima */\n    object-fit: contain; /* Garante que a imagem seja exibida proporcionalmente */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
