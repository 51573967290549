import React, { useEffect, useState } from "react";
import api from "../Services/api";
import EditUserModal from "../Components/EditUserModal";
import "./Style/ManageUsers.css";
import LogoutButton from "../Components/LogoutButton";
import BackIcon from "../Components/BackIcon";
import Logo from "../Components/Logo";

interface User {
  id: number;
  name: string;
  email: string;
  cargo?: string;
  status?: string;
  password?: string;
}

const ManageUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isCreatingUser, setIsCreatingUser] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await api.get("/users");
        setUsers(response.data);
      } catch (err: any) {
        setError("Erro ao carregar usuários.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/users/delete/${id}`);
      setUsers(users.filter((user) => user.id !== id));
    } catch (err) {
      setError("Erro ao excluir o usuário.");
    }
  };

  const handleSave = async (updatedUser: User) => {
    try {
      if (isCreatingUser) {
        // Envia apenas os dados necessários para criar o usuário
        const { name, email, password } = updatedUser;
        const response = await api.post("/users/create", { name, email, password });
        setUsers([...users, response.data]);
        setIsCreatingUser(false);
      } else {
        // Atualiza um usuário existente
        const { id, ...data } = updatedUser;
        await api.put(`/users/update/${id}`, data);
        setUsers(users.map((user) => (user.id === id ? updatedUser : user)));
        setSelectedUser(null);
      }
    } catch (err) {
      setError("Erro ao salvar o usuário.");
    }
  };

  return (
    <div className="manage-users">

      <div>
        <LogoutButton />
        <BackIcon />
        <Logo />
      </div>
      <h1>Gerenciamento de Usuários</h1>
      <button className="create-btn" onClick={() => setIsCreatingUser(true)}>
        Criar Usuário
      </button>
      {error && <p className="error">{error}</p>}

      {isLoading ? (
        <p>Carregando usuários...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Cargo</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.cargo || "-"}</td>
                <td>{user.status || "-"}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => setSelectedUser(user)}
                  >
                    Editar
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(user.id)}
                  >
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal para edição */}
      {selectedUser && (
        <EditUserModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          onSave={handleSave}
        />
      )}

      {/* Modal para criação */}
      {isCreatingUser && (
        <EditUserModal
          isNewUser
          onClose={() => setIsCreatingUser(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default ManageUsers;
