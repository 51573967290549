import React from "react";
import "./Style/ThankYouPage.css";
import LogoutButton from "../Components/LogoutButton";

const ThankYouPage: React.FC = () => {
  
	return (
	  <div className="thank-you-page">
		<img
		  src="/GMnegativoazul.png"
		  alt="Logo"
		  className="logo"
		/>
		<h1 className="thank-you-text">Obrigado por responder o questionário!</h1>
		<LogoutButton/>

	  </div>
	);
  };
  
  export default ThankYouPage;