// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-icon {
	position: fixed;
	top: 20px; /* Alinhado ao canto superior esquerdo */
	left: 20px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	z-index: 1000;
  }
  
  .back-icon .icon {
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .back-icon:hover .icon {
	transform: scale(1.1); /* Efeito de zoom ao passar o mouse */
	opacity: 0.8; /* Reduz a opacidade */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Style/BackIcon.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,SAAS,EAAE,wCAAwC;CACnD,UAAU;CACV,WAAW;CACX,YAAY;CACZ,eAAe;CACf,aAAa;EACZ;;EAEA;CACD,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,kDAAkD;EACjD;;EAEA;CACD,qBAAqB,EAAE,qCAAqC;CAC5D,YAAY,EAAE,sBAAsB;EACnC","sourcesContent":[".back-icon {\n\tposition: fixed;\n\ttop: 20px; /* Alinhado ao canto superior esquerdo */\n\tleft: 20px;\n\twidth: 50px;\n\theight: 50px;\n\tcursor: pointer;\n\tz-index: 1000;\n  }\n  \n  .back-icon .icon {\n\twidth: 100%;\n\theight: 100%;\n\tobject-fit: contain;\n\ttransition: transform 0.3s ease, opacity 0.3s ease;\n  }\n  \n  .back-icon:hover .icon {\n\ttransform: scale(1.1); /* Efeito de zoom ao passar o mouse */\n\topacity: 0.8; /* Reduz a opacidade */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
