// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-button {
	position: fixed;
	bottom: 20px;
	left: 20px;
	background-color: #b7976d;
	color: white;
	border: none;
	border-radius: 8px;
	padding: 10px 20px;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.2s ease;
	z-index: 1000;
  }
  
  .logout-button:hover {
	background-color: #ce9243;
	transform: scale(1.05);
  }
  
  .logout-button:active {
	background-color: #93074d;
	transform: scale(0.95);
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Style/LogoutButton.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,YAAY;CACZ,UAAU;CACV,yBAAyB;CACzB,YAAY;CACZ,YAAY;CACZ,kBAAkB;CAClB,kBAAkB;CAClB,eAAe;CACf,gBAAgB;CAChB,eAAe;CACf,2DAA2D;CAC3D,aAAa;EACZ;;EAEA;CACD,yBAAyB;CACzB,sBAAsB;EACrB;;EAEA;CACD,yBAAyB;CACzB,sBAAsB;EACrB","sourcesContent":[".logout-button {\n\tposition: fixed;\n\tbottom: 20px;\n\tleft: 20px;\n\tbackground-color: #b7976d;\n\tcolor: white;\n\tborder: none;\n\tborder-radius: 8px;\n\tpadding: 10px 20px;\n\tfont-size: 1rem;\n\tfont-weight: 600;\n\tcursor: pointer;\n\ttransition: background-color 0.3s ease, transform 0.2s ease;\n\tz-index: 1000;\n  }\n  \n  .logout-button:hover {\n\tbackground-color: #ce9243;\n\ttransform: scale(1.05);\n  }\n  \n  .logout-button:active {\n\tbackground-color: #93074d;\n\ttransform: scale(0.95);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
